import {ExternalLink, Note} from 'app/styles/InfoText';
import React from 'react';
import styled from 'styled-components';
import {COLORS} from '@fupa/fupa-uikit';

const NoteContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0 0.5rem;
  align-self: center;
  margin-bottom: 0.75rem;
  text-align: center;
  @media screen and (min-width: 500px) {
    padding: 0 2.5rem;
  }
`;

const LegalNote = () => {
  return (
    <NoteContainer>
      <Note variant='caption1'>
        Wenn du fortfährst, stimmst du den
        <ExternalLink
          tabIndex='2'
          color={COLORS.fupaSecondary}
          target='_blank'
          href='https://www.fupa.net/nutzungsbedingungen.html'
        >
          &nbsp;Nutzungsbedingungen&nbsp;
        </ExternalLink>
        sowie der
        <ExternalLink
          tabIndex='2'
          color={COLORS.fupaSecondary}
          target='_blank'
          href='https://www.fupa.net/datenschutz.html'
        >
          &nbsp;Datenschutzrichtlinie&nbsp;
        </ExternalLink>
        von FuPa zu. Zudem erklärst du dich damit einverstanden, dass durch die Nutzung von Facebook/Google-Connect
        Daten an FuPa übermittelt und verwendet werden.
      </Note>
    </NoteContainer>
  );
};

export {LegalNote};
